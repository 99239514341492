:root {
  --intuita-main: #2f80ed;
  --intuita-red: #eb5757;
  --intuita-main-darker: #1f3e66;
  --intuita-secondary: #88298a;
  --intuita-page-bg: #f4f7fd;
}

.cursor-pointer {
  cursor: pointer;
}

.icon-bg_filled {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  background: var(--intuita-main);
  align-items: center;
  cursor: pointer;
}

.icon-bg_empty {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  border: 1px solid var(--intuita-main);
  align-items: center;
  cursor: pointer;
}

.icon-bg_grayed {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: 1px solid #bdbdbd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-bg_disabled {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  background: #9e9e9e;
  align-items: center;
}

.table-header {
  position: sticky;
  top: 0;
  background-color: #f8f9fa;
  z-index: 1;
  color: black;
  text-transform: capitalize;
  letter-spacing: 0.06em;
  font-weight: 700;
}

.ag-root ::-webkit-scrollbar {
  width: 7.5px;
}

.ag-root ::-webkit-scrollbar:horizontal {
  height: 7.5px;
}

.ag-root ::-webkit-scrollbar-track,
.ag-root ::-webkit-scrollbar-track:horizontal {
  padding: 2px 0;
  background-color: #c9c9c9;
}
.ag-root ::-webkit-scrollbar-thumb,
.ag-root ::-webkit-scrollbar-thumb:horizontal {
  border-radius: 0.625rem;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #aaa9a9;
  /* border: 1px solid #000; */
}
​

/* Scroll Bar Custom Stylization */

.overflow-style::-webkit-scrollbar-track, .overflow-style::-webkit-scrollbar-track:horizontal {
  padding: 2px 0;
  background-color: #c9c9c9;
}

.overflow-style::-webkit-scrollbar {
  width: 7.5px;
}

.overflow-style::-webkit-scrollbar:horizontal {
  height: 7.5px;
}

.overflow-style::-webkit-scrollbar-thumb,
.overflow-style::-webkit-scrollbar-thumb:horizontal {
  border-radius: 0.625rem;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #aaa9a9;
  /* border: 1px solid #000; */
}

body {
  margin: 0;
  background-color: var(--intuita-page-bg) !important;
  font-family: 'Montserrat', 'Segoe UI', 'Open Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
